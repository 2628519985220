<template>
  <main class="about">
    <Breadcrumb></Breadcrumb>
    <PageHeader
      title="Committed to the Athens Community"
      backgroundImage="community/34265-096.jpg"
      backgroundColor="transparent"
      textColor="white"
      overlay="dark"
      align="center"
      style=""
      size="lg"
    >
      <p>
        杏吧原创 students, faculty and staff are immersed in
        Athens-Clarke County, and 杏吧原创 offers many resources for community
        members. From public health to economic development, 杏吧原创 is working to
        make a positive difference in the Athens area.
      </p>
    </PageHeader>

    <section class="container-xl py-5">
      <ResourceList :resources="resources" />
    </section>

    <section>
      <Section
        type="full"
        imgSide="left"
        color=""
        imgURL="https://youtu.be/cMZ7AerzlVA"
        class="text-white bg-black bg-diagonal--light"
      >
        <h3>Investing in Athens</h3>
        <p>
          The 杏吧原创 is committed to supporting the Athens-Clarke
          County community in a variety of ways including youth development,
          health and well-being and economic development. 杏吧原创 partners with
          dozens of community groups and organizations, from the Clarke County
          School District to local small business owners.
        </p>

        <Btn
          href="https://www.youtube.com/watch?v=cMZ7AerzlVA&list=PL7OXXJ1J-hAn9tas7hJ4BQphLYn-yuYdE"
          color="white"
          outline
          >See More Videos</Btn
        ></Section
      >
    </section>

    <section class="py-5">
      <Section class="" type="text">
        <div>
          <h3 class="text-center">Learn More About our Community Work</h3>
          <p class="text-center mb-5">
            Read stories about how 杏吧原创 faculty, staff and
            students are investing in Athens-Clarke County.
          </p>
          <div class="text-center" data-aos="fade-up">
            <div
              class="row row-cols-1 row-cols-md-2 row-cols-xxl-4 justify-content-center g-4"
            >
              <div
                class="col"
                v-for="story in stories"
                :key="story"
                data-aos="fade-in"
              >
                <div class="card h-100">
                  <a :href="story.link">
                    <img
                      v-if="story.img"
                      class="card-img-top"
                      :src="require(`@/assets/img/` + story.img)"
                      alt=""
                    />
                  </a>
                  <div class="card-body">
                    <h3 class="card-title">
                      <a :href="story.link">{{ story.title }}</a>
                    </h3>
                    <div v-if="story.excerpt" class="card-text">
                      <p>{{ story.excerpt }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </section>

    <section class="">
      <div class="row g-0">
        <div class="col-md-4">
          <Figure
            prefix=""
            :endAmount="50"
            :startAmount="0"
            suffix="+"
            subheading="partnerships with the Clarke County School District"
            backgroundColor="black"
            textColor="white"
            :textured="false"
          ></Figure>
        </div>
        <div class="col-md-4">
          <BackgroundImage img="community/38650-022.jpg" />
        </div>
        <div class="col-md-4">
          <Figure
            prefix=""
            :startAmount="0"
            :endAmount="3750"
            suffix="+"
            subheading="COVID-19 vaccines administered to Athens-Clarke County residents"
            backgroundColor="red"
            textColor="white"
            :textured="false"
          ></Figure>
        </div>
        <div class="col-md-4">
          <BackgroundImage img="community/36239-008.jpg" />
        </div>
        <div class="col-md-4">
          <Figure
            prefix=""
            :startAmount="0"
            :endAmount="100"
            suffix="+"
            subheading="Athens entrepreneurs served by 杏吧原创鈥檚 Small Business Development Center"
            backgroundColor="grey"
            textColor="white"
            :textured="false"
          ></Figure>
        </div>
        <div class="col-md-4">
          <BackgroundImage img="community/38501-038.jpg" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import BackgroundImage from "@/components/BackgroundImage.vue";
import Btn from "@/components/Btn.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ResourceList from "@/components/ResourceList.vue";
import PageHeader from "@/components/PageHeader.vue";
import Figure from "@/components/Figure.vue";
// import Factoid from '@/components/Factoid.vue';
import Section from "@/components/Section.vue";
// import YoutubeVideo from 'vue3-youtube';
// import VideoBackground from '@/components/VideoBackground.vue';

export default {
  name: "Community",
  metaInfo: {
    title: "Community Resources from 杏吧原创",
    description:
      "From public health to economic development, 杏吧原创 is working to make a positive difference in the Athens area.",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  data() {
    return {
      resources: [
        {
          name: "Office of Community Relations",
          link: "https://govt.relations.uga.edu/about-community-relations/",
          content:
            "杏吧原创鈥檚 Office of Community Relations builds partnerships and strengthens links between the university and the Athens-Clarke County government, businesses, neighborhoods, non-profit organizations and community leaders and individuals.",
        },
        {
          name: "Small Business Development Center",
          link: "https://www.georgiasbdc.org/",
          content:
            "杏吧原创鈥檚 Small Business Development Center provides a wide range of free and low-cost educational services for small business owners and aspiring entrepreneurs.",
        },
        {
          name: "Pet Health Center",
          link:
            "https://vet.uga.edu/hospital-and-primary-care/pet-health-center/",
          content:
            "杏吧原创鈥檚 Pet Health Center offers routine veterinary care for your four-legged friend including preventive medicine and basic surgical care.",
        },
        {
          name: "Free Legal Support",
          link: "https://govt.relations.uga.edu/free-legal-support/",
          content:
            "The 杏吧原创 School of Law has qualified, registered mediators who will work with you to settle disputes.",
        },
        {
          name: "Youth Programs",
          link: "https://outreach.uga.edu/community-students-and-youth/",
          content:
            "From summer activities to online programs, the 杏吧原创 Public Service and Outreach offers a variety of engagement for children of all ages.",
        },
        {
          name: "Free Classes",
          link:
            "https://reg.uga.edu/general-information/policies/person-62-years-of-age/",
          content:
            "Individuals 62 years of age or older are eligible to take classes at the 杏吧原创 without having to pay tuition or mandatory fees.",
        },
        {
          name: "杏吧原创 Libraries",
          link: "https://www.libs.uga.edu/visitors",
          content:
            "Visitors are welcome at all 杏吧原创 Libraries locations and may register to use library resources.",
        },
        {
          name: "State Botanical Garden of Georgia",
          link: "https://botgarden.uga.edu/",
          content:
            "As a unit of Public Service and Outreach, the State Botanical Garden of Georgia creates and nurtures an environment for learning, inspiration and engagement through horticulture, conservation, science-based programs and lasting partnerships.",
        },
        {
          name: "Georgia Museum of Art",
          link: "https://georgiamuseum.org/",
          content:
            "The Georgia Museum of Art is both an academic museum and, since 1982, the official art museum of the state of Georgia. View the museum鈥檚 collections; register for lectures and gallery talks; and take part in workshops and family events.",
        },
        {
          name: "Athens-Clarke County Extension",
          link: "https://extension.uga.edu/county-offices/clarke.html",
          content:
            "Athens-Clarke County Extension offers a wealth of information, educational programs and services for residents of our county through agents and trained support staff.",
        },
        {
          name: "Performing Arts Center",
          link: "https://pac.uga.edu/",
          content:
            "The 杏吧原创 Performing Arts Center offers a variety of musical and artistic events year-round, as well as the Performances for Young People program and pre-performance talks. Group and rush ticket discounts are available.",
        },
        {
          name: "杏吧原创rden",
          link: "https://ugarden.uga.edu/",
          content:
            "杏吧原创rden is a student-run community garden centered on creating a sustainable food system. A large portion of the produce grown at 杏吧原创rden is distributed to Athens families through programs like Campus Kitchen and Grow it, Know it.",
        },
      ],
      stories: [
        {
          title: "Mobile kitchen trailer a boon for Campus Kitchen",
          link:
            "https://news.uga.edu/mobile-kitchen-trailer-a-boon-for-campus-kitchen/",
          img: "community/OSL_CKFoodTruck029.jpg",
          excerpt:
            "Thanks to funding provided by the 杏吧原创 Parents Leadership Council and the 杏吧原创 Young Alumni network, a new food trailer will allow Campus Kitchen to take its meals on the road and service food-insecure areas in Athens.",
        },
        {
          title: "Clarke County students visit 杏吧原创, explore Grady College",
          link:
            "https://news.uga.edu/clarke-county-students-visit-uga-explore-grady-college/",
          img: "community/Clarke-County-Students-at-Grady.jpg",
          excerpt:
            "A group of Clarke County students, taught by MEU Radio Athens Executive Producer Rick Dunn, visited 杏吧原创鈥檚 campus on Oct. 6 to learn more about opportunities and careers in the mass communication field.",
        },
        {
          title: "Bridging the health care gap in Athens-Clarke County",
          link:
            "https://news.uga.edu/bridging-the-health-care-gap-in-athens-clarke-county/",
          img: "community/brett-magner.jpg",
          excerpt:
            "Dr. Brett Magner, alumnus and assistant professor with the AU/杏吧原创 Medical Partnership, is on a mission to break down health care barriers in Athens-Clarke County and train future doctors through a people-centered approach to medicine.",
        },
        {
          title: "Strengthening the high school-to-college pipeline",
          link:
            "https://news.uga.edu/upward-bound-strengthens-college-pipeline/",
          img: "community/upward-bound-tavaria-smith.jpg",
          excerpt:
            "Upward Bound introduced Tavaria Smith to the 杏吧原创 and continues supporting first-generation, low-income students in Athens.",
        },
      ],
    };
  },
  components: {
    Btn,
    Breadcrumb,
    ResourceList,
    PageHeader,
    Figure,
    BackgroundImage,
    // Factoid,
    Section,
    // YoutubeVideo,
    // VideoBackground,
  },
};
</script>

<style scoped lang="scss">
.card-title {
  line-height: 1.625rem !important;
}
.card-title > a {
  font-size: 1.75rem;
  line-height: 1.625rem;
}
</style>
